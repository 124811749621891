import * as React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/MasterLayout/MasterLayout";
import { MDBContainer, MDBRow, MDBCol, MDBDataTable } from "mdbreact";
import BannerCarousel from "../components/Headers/BannerCarousel/BannerCarousel";
import SEO from "../components/SEO/SEO";
import CardEdital from "../components/CardEdital/CardEdital";

export default function IndexPage({ pageContext: { editais } }) {
	IndexPage.propTypes = {
		pageContext: PropTypes.object.isRequired,
		editais: PropTypes.object.isRequired, // Array de ediais
	};

	const data = {
		columns: [
			{
				label: "Título",
				field: "titulo",
				width: 200,
				attributes: {
					"className": "d-none"
				}
			},
		],
		rows: editais?.map((i) => {
			i.titulo = <CardEdital editalInfo={i} btnIsrightAligned={true}/>;
			return i;
		})
	};

	return (
		<Layout>
			<SEO
				title="Todos os editais"
				description="Confira todos os editais da Universidade Feevale"
			/>
			<MDBContainer fluid>
				<MDBRow>
					<MDBCol style={{ paddingLeft: 0, paddingRight: 0 }}>
						<BannerCarousel bannerKey="DA3CD5BE-CEF9-431A-A8AF-161D24BB025E" />
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<MDBContainer>
				<MDBRow>
					<MDBCol className="text-center text-md-left mt-4">
						<h1 style={{color: "#2ec034"}}>Todos os editais</h1>
						<p>Confira todos os editais abertos na Universidade Feevale.</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<MDBDataTable
							borderless
							small
							barReverse
							searchTop
							searchBottom={false}
							data={data}
							entries={20}
							infoLabel={["Mostrando resultados entre", "e", "de", "editais"]}
							paginationLabel={["Anterior", "Próximo"]}
							noRecordsFoundLabel="Nenhum resultado encontrado"
							entriesLabel="Mostrar editais"
							searchLabel="Procurar"
						/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
