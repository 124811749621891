/* eslint-disable react/prop-types */
import React from "react";
import {
	MDBCardTitle,
	MDBCardText,
	MDBCardBody,
	MDBCard,
	MDBBtn,
	MDBIcon,
	MDBCol
} from "mdbreact";
import { Link } from "gatsby";

export default function CardEdital({ editalInfo, btnIsrightAligned = false }) {
	return (
		<MDBCard className="h-100 z-depth-0">
			<MDBCardBody>
				<span style={{ fontWeight: 500 }} className="text-green">
					{editalInfo.Periodo}
				</span>
				<MDBCardTitle>
					<a style={{ color: "#6d6e72" }} href={editalInfo.Acesso}>
						{editalInfo.Titulo}
					</a>
				</MDBCardTitle>
				{editalInfo?.Resumo?.length > 0 && 
					<MDBCardText>{editalInfo.Resumo}...</MDBCardText>
				}
			</MDBCardBody>
			<MDBCol className={btnIsrightAligned && "text-md-right" }>
				{editalInfo.Acesso.startsWith("https://") ? (
					<MDBBtn className="btn-yellow px-5 py-2 mx-3 mb-3" href={editalInfo.Acesso} target="_blank">
						<MDBIcon
							icon="chevron-circle-right"
							aria-hidden="true"
						/>{" "}
						Acesse
					</MDBBtn>
				) : (
					<Link to={editalInfo.Acesso}>
						<MDBBtn className="btn-yellow px-5 mx-3 mb-3">
							<MDBIcon
								icon="chevron-circle-right"
								aria-hidden="true"
							/>{" "}
							Acesse
						</MDBBtn>
					</Link>
				)}
			</MDBCol>
		</MDBCard>
	);
}
